header{
    background-color:#00415f ;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    .logo{
        width: 80%;
        img{
            width: 100%;
            height: auto;
        }
    }
}
@media only screen and (min-width: 600px) {
    header{
        justify-content: flex-start;
        .logo{
            margin-left: 50px;
            width: 20%;
            img{
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    header{
        .logo{
            margin-left: 100px;
            img{
            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    header{
        .logo{
            margin-left: 100px;
            img{
            }
        }
    }
}