.content{
    width: 94%;
    margin: 0 auto;
    .row{
        margin-bottom: 35px;
        .col-sm-12{
            margin: 60px 0;
        }
        .btn.btn-primary{
            
        }
        .photo-panel{
            margin-bottom: 50px;
        }
    }
}
@media only screen and (min-width: 960px) {
    .content{
        
        .row{
            margin-bottom: 50px;
            .col-sm-12{
                margin: 0;
            }
            .btn.btn-primary{
                &:hover{
                }
            }
            .photo-panel{
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .content{
        width: 1200px;
        margin: 0 auto;
        .row{
            margin-bottom: 50px;
            .col-sm-12{
                margin: 0;
            }
            .btn.btn-primary{
                &:hover{
                }
            }
            .photo-panel{
            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    .content{
        width: 1400px;
        .row{
            .col-sm-12{
            }
            .btn.btn-primary{
                &:hover{
                }
            }
            .photo-panel{
            }
        }
    }
}