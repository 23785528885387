* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  margin-bottom: 25px;
  font-size: 36px;
}

h2 {
  margin-bottom: 25px;
  font-size: 30px;
}

h3 {
  margin-bottom: 25px;
  font-size: 25px;
}

h4 {
  margin-bottom: 25px;
  font-size: 20px;
}

header {
  background-color: #00415f;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
header .logo {
  width: 80%;
}
header .logo img {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 600px) {
  header {
    justify-content: flex-start;
  }
  header .logo {
    margin-left: 50px;
    width: 20%;
  }
}
@media only screen and (min-width: 1280px) {
  header .logo {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 1600px) {
  header .logo {
    margin-left: 100px;
  }
}
.content {
  width: 94%;
  margin: 0 auto;
}
.content .row {
  margin-bottom: 35px;
}
.content .row .col-sm-12 {
  margin: 60px 0;
}
.content .row .photo-panel {
  margin-bottom: 50px;
}

@media only screen and (min-width: 960px) {
  .content .row {
    margin-bottom: 50px;
  }
  .content .row .col-sm-12 {
    margin: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .content {
    width: 1200px;
    margin: 0 auto;
  }
  .content .row {
    margin-bottom: 50px;
  }
  .content .row .col-sm-12 {
    margin: 0;
  }
}
@media only screen and (min-width: 1600px) {
  .content {
    width: 1400px;
  }
}