*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
h1{
margin-bottom: 25px;
font-size: 36px;
}
h2{
margin-bottom: 25px;
font-size: 30px;
}
h3{
margin-bottom: 25px;
font-size: 25px;
}
h4{
    margin-bottom: 25px;
    font-size: 20px;
}